import React, { useState } from "react";
import "../Style/Header.css";
import { images } from "../Library/images";
import { Link, useNavigate } from "react-router-dom"; // Using useNavigate
import SearchIcon from "@mui/icons-material/Search"; // Use MUI v5 icons
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { actionTypes } from "../ContextAPI/Reducer";
import { useStateValue } from "../ContextAPI/StateProvider";
import { auth } from "../Firebase/Firebase";

function Header() {
  const [{ basket, user }, dispatch] = useStateValue(); // Combined destructuring
  const [input, setInput] = useState("");
  const navigate = useNavigate(); // useNavigate hook

  function login() {
    if (user) {
      auth.signOut();
    }
  }

  // SEARCH
  function search(e) {
    e.preventDefault();
    dispatch({
      type: actionTypes.SET_SEARCH_TERM,
      term: input,
    });
    navigate("/search"); // Use navigate instead of history
  }

  return (
    <nav className="header">
      <Link to="/">
        <img className="header_logo" src={images.logo} alt="logo" />
      </Link>

      <form className="header_search" onSubmit={search}>
        <div className="header_search">
          <SearchIcon className="header_searchIcon" />
          <input
            value={input}
            onChange={(e) => setInput(e.currentTarget.value.toLowerCase())}
            type="text"
            placeholder="Search Itineraries,  Circuits, Golf Course,   Hotels  and  Excursions."
            className="header_searchInput"
          />
        </div>
        
        {input && (
          <button type="submit" className="search_button">
            SEARCH
          </button>
        )}
      </form>

      <div className="header_nav">
        <Link to={!user && "/"} className="header_link">
          <div onClick={login} className="header_option">
            <span className="header_optionLineOne">
              <AccountCircleOutlinedIcon /> {user?.email}
            </span>
            <span className="header_optionLineTwo">
              {user ? "Sign Out" : "Login"}
            </span>
          </div>
        </Link>

        <Link to="/" className="header_link">
          <div className="header_option">
            <span className="header_optionLineOne gone">
              <HelpOutlineOutlinedIcon />
            </span>
            <span className="header_optionLineTwo gone">Help</span>
          </div>
        </Link>

        <Link to="/checkout" className="header_link">
          <div className="header_optionBasket">
            <span className="header_optionBasket_title gone">Cart</span>
            <ShoppingCartOutlinedIcon className="cart_img" />
            <span className="header_optionLineTwo header_basketCount">
              {basket?.length}
            </span>
          </div>
        </Link>
      </div>
    </nav>
  );
}

export default Header;
