// intro.jsx
import React from 'react';
import '../Style/Intro.css';
import { Link } from 'react-router-dom';
import Video from '../video/Shantels attaempt at editing.mp4'; // Correct import for video
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import GolfCourseOutlinedIcon from '@mui/icons-material/GolfCourseOutlined';
import PetsOutlinedIcon from '@mui/icons-material/PetsOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import Diversity1Icon from '@mui/icons-material/Diversity1'; // About Us icon
import PhoneIcon from '@mui/icons-material/Phone'; // Contact icon
import LoyaltyIcon from '@mui/icons-material/Loyalty'; // Blog icon
import { Box } from '@mui/material';

function scrollToSection(id) {
  const element = document.getElementById(id);
  if (element) {
      window.scrollTo({
          top: element.offsetTop - 100, // Adjust for header height
          behavior: 'smooth',
      });
  }
}

function Intro() {
  return (
    <Box className="intro">
      {/* Left Section - Categories */}
      <Box className="categories">
        <Link>
          <Box className="category_items" onClick={() => scrollToSection('itineraries')}>
            <BookOutlinedIcon />
            <span>Itineraries</span>
          </Box>
        </Link>
        <Link>
          <Box className="category_items" onClick={() => scrollToSection('circuits')}>
            <TravelExploreOutlinedIcon />
            <span>Circuits</span>
          </Box>
        </Link>
        <Link>
          <Box className="category_items" onClick={() => scrollToSection('golf-courses')}>
            <GolfCourseOutlinedIcon />
            <span>Golf Courses</span>
          </Box>
        </Link>
        <Link>
          <Box className="category_items" onClick={() => scrollToSection('excursions')}>
            <PetsOutlinedIcon />
            <span>Excursions</span>
          </Box>
        </Link>
        <Link>
          <Box className="category_items" onClick={() => scrollToSection('hotels')}>
            <HomeWorkOutlinedIcon />
            <span>Hotels</span>
          </Box>
        </Link>
      </Box>

      {/* Center Section - Showcase */}
      <Box className="showcase">
        <span className="home_image" />
      </Box>

      {/* Right Section - Additional Links */}
      <Box className="right_section">
      <Box className="right_section_content">
          <Link to="/contact" className="custom_link">
            <Box
              className="right_section_content_items"
              sx={{
                color: '#483c32',
                transition: 'color 0.3s ease',
                '&:hover': {
                  color: '#c16921',
                  '.MuiSvgIcon-root': {
                    color: '#c16921', // Updates the icon color on hover
                  },
                },
              }}
            >
              <PhoneIcon />
              <span className="title">
                CONTACT
                <br />
                <span className="desc">Contact our Tours Agent</span>
              </span>
            </Box>
          </Link>
          <Link to="/about" className="custom_link">
            <Box
              className="right_section_content_items"
              sx={{
                color: '#483c32',
                transition: 'color 0.3s ease',
                '&:hover': {
                  color: '#c16921',
                  '.MuiSvgIcon-root': {
                    color: '#c16921', // Updates the icon color on hover
                  },
                },
              }}
            >
              <Diversity1Icon />
              <span className="title">
                ABOUT US
                <br />
                <span className="desc">Get to know us</span>
              </span>
            </Box>
          </Link>
          <Link to="/blog" className="custom_link">
            <Box
              className="right_section_content_items"
              sx={{
                color: '#483c32',
                transition: 'color 0.3s ease',
                '&:hover': {
                  color: '#c16921',
                  '.MuiSvgIcon-root': {
                    color: '#c16921', // Updates the icon color on hover
                  },
                },
              }}
            >
              <LoyaltyIcon />
              <span className="title">
                Blog
                <br />
                <span className="desc">Read our blogs</span>
              </span>
            </Box>
          </Link>
        </Box>

        <Box className="right_section_ads">
          <video autoPlay muted loop src={Video} alt="Ad" />
        </Box>
      </Box>
    </Box>
  );
}

export default Intro;
