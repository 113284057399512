// src/Components/ProjectCardBlue.jsx

import React from 'react';
import { Box, Typography } from '@mui/material';
import '../Style/projectBlue.css'; // Import the specific CSS for blue cards
import $clamp from 'clamp.js';

const ProjectCardBlue = ({ blog }) => {
    React.useEffect(() => {
        const boxes = document.querySelectorAll(".projcard-description");
        boxes.forEach(box => {
            $clamp(box, { clamp: 6 });
        });
    }, []);

    return (
        <Box className="projcard projcard-blue">
            <Box className="projcard-innerbox">
                <Box className="projcard-textbox">
                    <Typography variant="h6" className="projcard-title">{blog.title}</Typography>
                    <Typography variant="subtitle1" className="projcard-subtitle">{blog.subtitle}</Typography>
                    <Box className="projcard-bar"></Box>
                    <Typography className="projcard-description">{blog.description}</Typography>
                    <Box className="projcard-tagbox">
                        {blog.tags.map((tag, index) => (
                            <span key={index} className="projcard-tag">{tag}</span>
                        ))}
                    </Box>
                </Box>
                <Box className="projcard-imgbox">
                    <img className="projcard-img" src={blog.image} alt={blog.title} />
                </Box>
            </Box>
        </Box>
    );
};

export default ProjectCardBlue;
