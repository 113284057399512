// src/Components/Categories.jsx
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Pagination } from '@mui/material';
import Heading from './Heading';
import Product from './Product';
import { data } from '../Library/stock';
import WhatsAppFloatingButton from './WhatsAppFloatingButton';

function Categories() {
    const { category } = useParams();
    const itemsPerPage = 10; // Number of items to show per page
    const filteredItems = data.filter(item => item.category.toLowerCase() === category.replace(/-/g, ' ').toLowerCase());
    
    const [page, setPage] = useState(1);
    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <Box className="home">
            <Heading heading={category.charAt(0).toUpperCase() + category.slice(1)} />
            <Box className="home_row" display="flex" flexWrap="wrap">
                {filteredItems
                    .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                    .map(item => (
                        <Product
                            key={item.id}
                            product={item}
                        />
                    ))}
            </Box>
            <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                style={{ margin: '20px auto', display: 'flex', justifyContent: 'center' }}
            />
        <WhatsAppFloatingButton />
        </Box>
    );
}

export default Categories;
