// src/Components/Heading.jsx
import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import '../Style/heading.css';

function Heading({ heading }) {
    return (
        <div className="heading">
            <Typography variant="h5">{heading}</Typography>
            <Link to={`/category/${heading.toLowerCase().replace(/ /g, '-')}`} className="see-all">
                See All
            </Link>
        </div>
    );
}

export default Heading;
