import React from 'react';
import '../Style/error.css';
import { Box, Typography } from '@mui/material';

function Error({ product }) {
  return (
    <Box className="error">
      <Typography>
        Please! Search by Itinerray name, Circuit name, Golf Course name, Excursion name or Hotel name.
        <br /> Otherwise, what you looking for is not available as of todate. <strong>{product}</strong>
      </Typography>
    </Box>
  );
}

export default Error;

