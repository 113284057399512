import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC-jG1qONAZGlamM3qaolrhRziPBmdfhkQ",
  authDomain: "jumia-75d21.firebaseapp.com",
  projectId: "jumia-75d21",
  storageBucket: "jumia-75d21.appspot.com",
  messagingSenderId: "540432911955",
  appId: "1:540432911955:web:976c9985c5825bb57a40a6",
  measurementId: "G-5ZKDD82LQX"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export { db, auth };
