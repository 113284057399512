import React, { useState, useEffect } from 'react';
import itineraries from '../Library/itineraryLibrary';
import { useParams } from 'react-router-dom';
import { BorderAllTwoTone } from '@mui/icons-material';
import '../Style/Itinerary.css';

const Itinerary = () => {
    const { slug } = useParams();
    const [currentItinerary, setCurrentItinerary] = useState(null);
    const [currentDayIndex, setCurrentDayIndex] = useState(0);
    const [currentImageIndex1, setCurrentImageIndex1] = useState(0);
    const [currentImageIndex2, setCurrentImageIndex2] = useState(0);

    useEffect(() => {
        setCurrentItinerary(itineraries[slug]);
    }, [slug]);

    if (!currentItinerary) return <div>Itinerary not found!</div>;

    const currentDay = currentItinerary.days[currentDayIndex];

    return (
        <div className="container1">
            <div className="sidebar">
                <h2>{currentItinerary.name} Itinerary</h2>
                <ul className="days-list">
                    {currentItinerary.days.map((day, index) => (
                        <li key={index}>
                            <button1
                                href="#"
                                className={currentDayIndex === index ? "active" : ""}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentDayIndex(index);
                                    setCurrentImageIndex1(0);
                                    setCurrentImageIndex2(0);
                                }}
                            >
                                {day.title.includes("Included") || day.title.includes("Excluded")
                                    ? day.title
                                    : `Day ${index + 1}`}
                            </button1>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="main-content">
                <main className="content">
                    <h2 id="day-title">{currentDay.title}</h2>
                    <div className="timeline" id="timeline">
                        {currentDay.timelineItems.map((item, index) => (
                            <div className="timeline-item" key={index}>
                                <p>{item}</p>
                            </div>
                        ))}
                    </div>

                    <div className="navigation">
                        <button className="prev" onClick={() => {
                            const newIndex = Math.max(currentDayIndex - 1, 0);
                            setCurrentDayIndex(newIndex);
                            setCurrentImageIndex1(0);
                            setCurrentImageIndex2(0);
                        }}>
                            <BorderAllTwoTone /> Previous
                        </button>
                        <button className="next" onClick={() => {
                            const newIndex = Math.min(currentDayIndex + 1, currentItinerary.days.length - 1);
                            setCurrentDayIndex(newIndex);
                            setCurrentImageIndex1(0);
                            setCurrentImageIndex2(0);
                        }}>
                            Next <BorderAllTwoTone />
                        </button>
                    </div>
                </main>

                <div className="carousel-section">
                    {/* Carousel 1 */}
                    <div className="carousel" id="carousel1">
                        <div className="carousel-image">
                            {currentDay.carouselImages1.length > 0 ? (
                                <img
                                    src={currentDay.carouselImages1[currentImageIndex1]}
                                    alt="Carousel 1"
                                    onError={(e) => {
                                        console.error("Failed to load image: ", e.target.src);
                                        e.target.style.display = 'none';
                                    }} 
                                />
                            ) : (
                                <p>No images available for this day in Carousel 1.</p>
                            )}
                        </div>

                        <div className="carousel-navigation">
                            <span 
                                className="prev" 
                                onClick={() => setCurrentImageIndex1((prev) => (prev - 1 + currentDay.carouselImages1.length) % currentDay.carouselImages1.length)}
                            >
                                &lt;
                            </span>
                            <span 
                                className="next" 
                                onClick={() => setCurrentImageIndex1((prev) => (prev + 1) % currentDay.carouselImages1.length)}
                            >
                                &gt;
                            </span>
                        </div>

                        <div className="carousel-dots">
                            {currentDay.carouselImages1.map((_, index) => (
                                <span 
                                    key={index} 
                                    className={`dot ${currentImageIndex1 === index ? 'active' : ''}`} 
                                    onClick={() => setCurrentImageIndex1(index)}
                                ></span>
                            ))}
                        </div>
                    </div>

                    {/* Carousel 2 */}
                    <div className="carousel2" id="carousel2">
                        <div className="carousel-image">
                            {currentDay.carouselImages2.length > 0 ? (
                                <img
                                    src={currentDay.carouselImages2[currentImageIndex2]}
                                    alt="Carousel 2"
                                    onError={(e) => {
                                        console.error("Failed to load image: ", e.target.src);
                                        e.target.style.display = 'none';
                                    }} 
                                />
                            ) : (
                                <p>No images available for this day in Carousel 2.</p>
                            )}
                        </div>

                        <div className="carousel-navigation">
                            <span 
                                className="prev" 
                                onClick={() => setCurrentImageIndex2((prev) => (prev - 1 + currentDay.carouselImages2.length) % currentDay.carouselImages2.length)}
                            >
                                &lt;
                            </span>
                            <span 
                                className="next" 
                                onClick={() => setCurrentImageIndex2((prev) => (prev + 1) % currentDay.carouselImages2.length)}
                            >
                                &gt;
                            </span>
                        </div>

                        <div className="carousel-dots">
                            {currentDay.carouselImages2.map((_, index) => (
                                <span 
                                    key={index} 
                                    className={`dot ${currentImageIndex2 === index ? 'active' : ''}`} 
                                    onClick={() => setCurrentImageIndex2(index)}
                                ></span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Itinerary;
