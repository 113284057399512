import React from 'react';
import '../Style/search.css';
import { data } from '../Library/stock';
import { useStateValue } from '../ContextAPI/StateProvider';
import Heading from './Heading';
import Product from './Product';
import Error from './Error';
import { Box } from '@mui/material';

function Search() {
  const [{ term }] = useStateValue();

  const filteredContent = data.filter((item) => (
    item.product.toLowerCase().includes(term.toLowerCase()) || // Ensure term is in lowercase
    item.desc.toLowerCase().includes(term.toLowerCase()) ||
    item.category.toLowerCase().includes(term.toLowerCase())
  ));

  return (
    <Box className="search">
      <Box className="search_section">
        <Heading heading={term} className="heading" />
        <Box className="home_row" display="flex" flexWrap="wrap">
          {filteredContent.length === 0 ? (
            <Error product={term} className="heading" />
          ) : (
            filteredContent.map((item) => (
              <Product
                id={item.id}
                key={item.id}
                title={item.desc}
                price={item.price}
                rating={item.star}
                image={item.image}
              />
            ))
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Search;
