// src/Components/Home.jsx

import React from 'react';
import '../Style/Home.css';
import Product from './Product';
import Intro from './Intro';
import ItinerarySearchForm from './ItinerarySearchForm';
import LuxOdysseyBusinesses from './LuxOdysseyBusinesses';
import Heading from './Heading';
import { data } from '../Library/stock';
import blogs from '../Library/Blogs';
import { Box } from '@mui/material';
import ProjectCardBlue from './ProjectCardBlue';
import ProjectCardRed from './ProjectCardRed';
import WhatsAppFloatingButton from './WhatsAppFloatingButton';
import Partners from './Partners';

function Home() {
    return (
        <Box className="home">
            <Intro />
            <LuxOdysseyBusinesses />
            <ItinerarySearchForm/>

            {blogs.filter(blog => blog.type === 'blue').map(blog => (
                <ProjectCardBlue key={blog.id} blog={blog} />
            ))}

            <Box id="itineraries">
                <Heading heading="Itineraries" link="/itineraries" className="heading" />
                <Box className="home_row">
                    {data.filter(item => item.category === 'Itineraries').slice(0, 5).map(item => (
                        <Product key={item.id} product={{ ...item, link: `/itinerary/${item.product.toLowerCase().replace(/ /g, '-')}` }} />
                    ))}
                </Box>
            </Box>

            {blogs.filter(blog => blog.type === 'red').map(blog => (
                <ProjectCardRed key={blog.id} blog={blog} />
            ))}

            <Box id="circuits">
                <Heading heading="Circuits" link="/circuits" className="heading" />
                <Box className="home_row">
                    {data.filter(item => item.category === 'Circuits').slice(0, 5).map(item => (
                        <Product key={item.id} product={item} />
                    ))}
                </Box>
            </Box>

            {blogs.filter(blog => blog.type === 'green').map(blog => (
                <ProjectCardBlue key={blog.id} blog={blog} />
            ))}

            <Box id="golf-courses">
                <Heading heading="Golf Courses" link="/golf-courses" className="heading" />
                <Box className="home_row">
                    {data.filter(item => item.category === 'Golf Courses').slice(0, 5).map(item => (
                        <Product key={item.id} product={item} />
                    ))}
                </Box>
            </Box>

            <Box id="excursions">
                <Heading heading="Excursions" link="/excursions" className="heading" />
                <Box className="home_row">
                    {data.filter(item => item.category === 'Excursions').slice(0, 5).map(item => (
                        <Product key={item.id} product={item} />
                    ))}
                </Box>
            </Box>

            <Box id="hotels">
                <Heading heading="Hotels" link="/hotels" className="heading" />
                <Box className="home_row">
                    {data.filter(item => item.category === 'Hotels').slice(0, 5).map(item => (
                        <Product key={item.id} product={item} />
                    ))}
                </Box>
            </Box>

            {blogs.filter(blog => blog.type === 'custom').map(blog => (
                <ProjectCardRed key={blog.id} blog={blog} />
            ))}

            <Partners />
            <WhatsAppFloatingButton />
        </Box>
    );
}

export default Home;
