// src/Components/WhatsAppFloatingButton.jsx
import React from 'react';
import { Box } from '@mui/material';
import '../Style/WhatsAppFloatingButton.css';

const WhatsAppFloatingButton = () => {
    return (
        <Box className="float">
            <a
                href="https://api.whatsapp.com/send?phone=254722298457&text=Hello%21%20I%20would%20like%20more%20information%20about%20LuxOdysseySafaris%20Itineraries."
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="fab fa-whatsapp my-float"></i>
            </a>
        </Box>
    );
};

export default WhatsAppFloatingButton;
