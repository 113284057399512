// src/Components/Partners.jsx

import React from 'react';
import '../Style/Partners.css'; // Import the specific CSS for partners
import partner1 from '../images/Partners/TRA.png'; // Import the partner logos
import partner2 from '../images/Partners/Magicalkenya.png';


const Partners = () => {
    return (
        <div className="partner-section">
            <h2 className="partner-title">In Association With</h2>
            <div className="partner-container">
                {/* Partner 1 */}
                <div className="partner-card">
                    <div className="partner-logo">
                        <img src={partner1} alt="Partner 1 Logo" />
                    </div>
                    <div className="partner-info">
                        <h3>Licence No. TRA1/47/C01/65906</h3>
                    </div>
                </div>

                {/* Partner 2 */}
                <div className="partner-card">
                    <div className="partner-logo">
                        <img src={partner2} alt="Partner 2 Logo" />
                    </div>
                    <div className="partner-info">
                        <h3>Magical Kenya</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partners;
