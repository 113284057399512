import React from 'react';
import { useStateValue } from '../ContextAPI/StateProvider';
import CheckoutProduct from './CheckoutProduct';
import Subtotal from './Subtotal';
import { Box, Typography, ImageListItem } from '@mui/material';
import image from '../images/lux_images/luxodyssey_letterhead.png'

function Checkout() {
  const [{ basket }] = useStateValue();

  return (
    <Box className="checkout">
      <Box className="checkout_left">
        <ImageListItem>
          <img
            className="checkout_ad"
            src= {image}
            alt=""
          />
        </ImageListItem>
        {basket?.length === 0 ? (
          <Box>
            <Typography variant="h4">Your cart is empty</Typography>
            <Typography>Add an item to your cart</Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="h4" className="checkout_title">Your Shopping Cart</Typography>
            {basket?.map((item) => (
              <CheckoutProduct
                key={item.id}
                id={item.id}
                title={item.title}
                image={item.image}
                price={item.price}
                rating={item.rating}
              />
            ))}
          </Box>
        )}
      </Box>
      {basket.length > 0 && (
        <Box className="checkout_right">
          <Subtotal />
        </Box>
      )}
    </Box>
  );
}

export default Checkout;
