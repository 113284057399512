// src/Library/Blogs.js

const blogs = [
    {
        id: 1,
        type: 'blue',
        image: require('../images/Blogs/Image1.jpg'),
        title: 'Elevate Your Golfing Adventure',
        subtitle: 'Luxury meets Africa’s stunning landscapes',
        description: 'Immerse yourself in a journey where luxury meets Africa’s stunning landscapes. With Luxodyssey Safaris, you can choose from thoughtfully curated itineraries or create a customized adventure tailored to your unique tastes.',
        tags: ['Golf', 'Safari', 'Luxury']
    },
    {
        id: 2,
        type: 'red',
        image: require('../images/Blogs/Image2.jpg'),
        title: 'Beyond the Fairways',
        subtitle: 'Experience true luxury in the details',
        description: 'At Luxodyssey Safaris, we believe true luxury lies in the details. Our tours go beyond the game, offering experiences like world-class dining, lavish accommodations, and private explorations—all curated by our expert guides to enrich every moment of your journey.',
        tags: ['Experience', 'Dining', 'Guided Tours']
    },
    {
        id: 3,
        type: 'green',
        image: require('../images/Blogs/Image3.jpg'),
        title: 'Why Choose Luxodyssey Safaris?',
        subtitle: 'Unmatched Expertise, Exclusive Access, Tailored Service',
        description: 'With years of experience in luxury travel and golf tourism, we’re dedicated to delivering an experience where excellence meets personal touches. Enjoy privileged access to Africa’s most prestigious golf courses and receive personalized service from your first inquiry to the final putt.',
        tags: ['Expertise', 'Access', 'Service']
    },
    {
        id: 4,
        type: 'custom',
        color: '#F5AF41',
        image: require('../images/Blogs/Image4.jpg'),
        title: 'Curated or Custom, Perfectly Crafted for You',
        subtitle: 'Designed to suit every golfer’s desires',
        description: 'Choose from our exclusive itineraries or create a personalized journey. Whether you dream of a championship course, spa days, or thrilling safaris, Luxodyssey Safaris will shape each detail around your vision for the ultimate luxury experience.',
        tags: ['Customizable', 'Luxury', 'Adventure']
    },
];

export default blogs;
