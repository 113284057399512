import React, { useEffect, useRef } from 'react';
import emailjs from 'emailjs-com';
import '../Style/ContactUs.css';

const ContactUs = () => {
    const formRef = useRef();

    useEffect(() => {
        // Fade-in effect for the form on component mount
        const form = formRef.current;
        form.style.opacity = 0;
        form.style.transition = 'opacity 1s ease-in-out';
        setTimeout(() => {
            form.style.opacity = 1;
        }, 500);

        // Input field focus animation
        const inputs = document.querySelectorAll(".input, .textinput");
        inputs.forEach((input) => {
            input.addEventListener("focus", function () {
                input.style.transition = "all 0.3s ease-in-out";
                input.style.transform = "scale(1.05)";
                input.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
            });

            input.addEventListener("blur", function () {
                input.style.transform = "scale(1)";
                input.style.boxShadow = "none";
            });
        });

        // Button hover effect
        const submitButton = document.querySelector(".submit-btn");
        submitButton.addEventListener("mouseover", function () {
            submitButton.style.transition = "background-color 0.3s ease";
            submitButton.style.backgroundColor = "#d5a021"; // Darker green on hover
        });

        submitButton.addEventListener("mouseout", function () {
            submitButton.style.backgroundColor = "#d5a021"; // Original green
        });

        // Smooth scroll effect when clicking social media links
        const socialLinks = document.querySelectorAll(".social-svg");
        socialLinks.forEach((link) => {
            link.addEventListener("click", function (e) {
                e.preventDefault();
                window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: "smooth",
                });
            });
        });
    }, []); // Empty dependency array to run only on mount

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission

        emailjs.sendForm(
            'service_vz4laqs', // Service ID: service_vz4laqs
            'template_zs0ito5', // Template ID: template_zs0ito5
            formRef.current,    // Form reference for mapping field names
            '0o3L2ZbtvYo0XtEGD'   // Public Key: 0o3L2ZbtvYo0XtEGD
        ).then(
            (result) => {
                alert('Message sent successfully!');
                formRef.current.reset(); // Optional: Reset the form after submission
            },
            (error) => {
                alert('Failed to send message, please try again.');
                console.error(error.text);
            }
        );
    };

    return (
        <div className="contact_us_green">
            <div className="responsive-container-block big-container">
                <div className="responsive-container-block container">
                    <div className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-7 wk-ipadp-10 line" id="i69b-2">
                        <form className="form-box" ref={formRef} onSubmit={handleSubmit}>
                            <div className="container-block form-wrapper">
                                <div className="head-text-box">
                                    <p className="text-blk contactus-head">Contact us</p>
                                    <p className="text-blk contactus-subhead">
                                        We’d love to hear from you! Whether you have questions, feedback, or need assistance, our team is here to ensure you have the best experience possible. Don’t hesitate to reach out!
                                    </p>
                                </div>
                                <div className="responsive-container-block">
                                    <div className="responsive-cell-block wk-ipadp-6 wk-tab-12 wk-mobile-12 wk-desk-6" id="i10mt-6">
                                        <p className="text-blk input-title">FIRST NAME</p>
                                        <input className="input" id="ijowk-6" name="FirstName" required />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">LAST NAME</p>
                                        <input className="input" id="indfi-4" name="LastName" required />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">EMAIL</p>
                                        <input className="input" id="ipmgh-6" name="Email" required />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                        <p className="text-blk input-title">PHONE NUMBER</p>
                                        <input className="input" id="imgis-5" name="PhoneNumber" required />
                                    </div>
                                    <div className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="i634i-6">
                                        <p className="text-blk input-title">WHAT DO YOU HAVE IN MIND</p>
                                        <textarea className="textinput" id="i5vyy-6" name="message" placeholder="Please enter query..." required></textarea>
                                    </div>
                                </div>
                                <div className="btn-wrapper">
                                    <button type="submit" className="submit-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-5 wk-ipadp-10" id="ifgi">
                        <div className="container-box">
                            <div className="text-content">
                                <p className="text-blk contactus-head">Contact us</p>
                                <p className="text-blk contactus-subhead">
                                    We’d love to hear from you! Whether you have questions, feedback, or need assistance, our team is here to ensure you have the best experience possible. Don’t hesitate to reach out!
                                </p>
                            </div>
                            <div className="workik-contact-bigbox">
                                <div className="workik-contact-box">
                                    <div className="phone text-box">
                                        <img className="contact-svg" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET21.jpg" alt="Phone Icon" />
                                        <p className="contact-text">+254 734 338 794</p>
                                    </div>
                                    <div className="address text-box">
                                        <img className="contact-svg" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET22.jpg" alt="Address Icon" />
                                        <p className="contact-text">info@luxodysseysafaris.com</p>
                                    </div>
                                    <div className="mail text-box">
                                        <img className="contact-svg" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET23.jpg" alt="Mail Icon" />
                                        <p className="contact-text">Nairobi-Kenya: Sultan Estates Suite B9 | Karen.</p>
                                    </div>
                                </div>
                                <div className="social-media-links1">
                                    <a href="mailto:info@luxodysseysafaris.com">
                                        <img className="social-svg" id="is9ym" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-mail.svg" alt="Mail Icon" />
                                    </a>
                                    <a href="https://twitter.com/yourprofile">
                                        <img className="social-svg" id="i706n" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-twitter.svg" alt="Twitter Icon" />
                                    </a>
                                    <a href="https://www.instagram.com/yourprofile">
                                        <img className="social-svg" id="ib9ve" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-insta.svg" alt="Instagram Icon" />
                                    </a>
                                    <a href="https://www.facebook.com/yourprofile">
                                        <img className="social-svg" id="ie9fx" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-fb.svg" alt="Facebook Icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
