import React from 'react';
import {NumericFormat} from 'react-number-format';
import { getbasketTotal } from '../ContextAPI/Reducer';
import { useStateValue } from '../ContextAPI/StateProvider';
import { Box, Button, Modal, Typography } from '@mui/material';

function Subtotal() {
  const [{ basket }] = useStateValue();
  const [{ user }] = useStateValue();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box className="subtotal">
      <NumericFormat
        value={getbasketTotal(basket)}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'USG: '}
        renderText={(value) => (
          <>
            <Typography>
              Subtotal ({basket.length} items): <strong>{`${value}`}</strong>
            </Typography>
            <Typography>
              <input type="checkbox" />
              This tour package contains a gift
            </Typography>
          </>
        )}
      />
      <Button type="button" onClick={handleOpen}>
        CHECKOUT
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box>
          <Typography variant="h6">Purchased</Typography>
          <Typography>
            {user} Thank you for Purchasing from us.
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
}

export default Subtotal;
