import React from 'react';
import '../Style/ContactHeader.css'; // Importing CSS

const Header = () => {
    return (
        <header className="top-header">
            <div className="container">
                <div className="top-header-left">
                    <i className="fas fa-phone"></i> +254 734 338 794
                </div>
                <div className="top-header-right">
                    <a href="https://www.instagram.com/luxodysseygolfsafaris/"><i className="fab fa-facebook-f"></i></a>
                    <a href="https://www.instagram.com/luxodysseygolfsafaris/"><i className="fab fa-twitter"></i></a>
                    <a href="https://www.instagram.com/luxodysseygolfsafaris/"><i className="fab fa-instagram"></i></a>
                    <a href="https://www.instagram.com/luxodysseygolfsafaris/"><i className="fab fa-dribbble"></i></a>
                </div>
            </div>
        </header>
    );
};

export default Header;
