import React from 'react';
import { useStateValue } from '../ContextAPI/StateProvider';
import star from '../images/lux_images/star_yellow.png';
import '../Style/CheckoutProduct.css';
import {NumericFormat} from 'react-number-format';
import { Box, Typography, IconButton } from '@mui/material';


function CheckoutProduct({ id, title, image, price, rating }) {
  const [, dispatch] = useStateValue();

  const removeFromBasket = () => {
    dispatch({
      type: 'REMOVE_TO_BASKET',
      id: id,
    });
  };

  return (
    <Box className="CheckoutProduct">
      <NumericFormat
        value={price}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'UGX: '}
        renderText={(price) => (
          <Box display="flex">
            <img className="CheckoutProduct_image" src={image} alt="" />
            <Box className="CheckoutProduct_info">
              <Typography className="CheckoutProduct_title">{title}</Typography>
              <Typography className="CheckoutProduct_price">
                <strong>{price}</strong>
              </Typography>
              <Box className="CheckoutProduct_rating">
                {Array(rating)
                  .fill()
                  .map((_, index) => (
                    <img key={index} src={star} alt="" />
                  ))}
              </Box>
              <IconButton className="Checkout_button" onClick={removeFromBasket}>
                REMOVE
              </IconButton>
            </Box>
          </Box>
        )}
      />
    </Box>
  );
}

export default CheckoutProduct;
