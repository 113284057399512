// src/library/itineraryLibrary.js

const itineraries = {
    // Gold Package Itinerary (Kenya)
    'gold-package': {
        name: 'Gold Package',
        days: [
            {
                type: "day",title: "Day 1: Arrival & VIP Transfer",
                timelineItems: [
                    "Meet and greet at Nairobi Jomo Kenyatta International Airport.",
                    "VIP transfer to a selected 5-star hotel."
                ],
                carouselImages1: [
                    require('../images/GoldPackageItineraryImages/image1.jpg'),
                    require('../images/GoldPackageItineraryImages/image2.jpg'),
                    require('../images/GoldPackageItineraryImages/image3.jpg')
                ],
                carouselImages2: [
                    require('../images/GoldPackageItineraryImages/image4.jpg'),
                    require('../images/GoldPackageItineraryImages/image5.jpg'),
                    require('../images/GoldPackageItineraryImages/image6.jpg')
                ]
            },
            {
                type: "day",title: "Day 2: Game Drive & Golf",
                timelineItems: [
                    "Early morning game drive at the Nairobi National Park.",
                    "Refresh and siesta.",
                    "First golf round at the Karen Golf and Country Club."
                ],
                carouselImages1: [
                    require('../images/GoldPackageItineraryImages/image7.jpg'),
                    require('../images/GoldPackageItineraryImages/image8.jpg'),
                    require('../images/GoldPackageItineraryImages/image9.jpg')
                ],
                carouselImages2: [
                    require('../images/GoldPackageItineraryImages/image10.jpg'),
                    require('../images/GoldPackageItineraryImages/image11.jpg'),
                    require('../images/GoldPackageItineraryImages/image12.jpg')
                ]
            },
            {
                type: "day",title: "Day 3: Rift Valley Golf & Country Club",
                timelineItems: [
                    "Private charter transfer to the Great Rift Valley Golf and Country Club.",
                    "Personalized check-in.",
                    "Afternoon golf round."
                ],
                carouselImages1: [
                    require('../images/GoldPackageItineraryImages/image13.jpg'),
                    require('../images/GoldPackageItineraryImages/image14.jpg'),
                    require('../images/GoldPackageItineraryImages/image15.jpg')
                ],
                carouselImages2: [
                    require('../images/GoldPackageItineraryImages/image16.jpg'),
                    require('../images/GoldPackageItineraryImages/image17.jpg'),
                    require('../images/GoldPackageItineraryImages/image18.jpg')
                ]
            },
            {
                type: "day",title: "Day 4: Golf & Crescent Island Tour",
                timelineItems: [
                    "Morning golf round.",
                    "Afternoon excursions: private boat tour of the Crescent Island Game Park."
                ],
                carouselImages1: [
                    require('../images/GoldPackageItineraryImages/image19.jpg'),
                    require('../images/GoldPackageItineraryImages/image20.jpg'),
                    require('../images/GoldPackageItineraryImages/image21.jpg')
                ],
                carouselImages2: [
                    require('../images/GoldPackageItineraryImages/image22.jpg'),
                    require('../images/GoldPackageItineraryImages/image23.jpg'),
                    require('../images/GoldPackageItineraryImages/image24.jpg')
                ]
            },
            {
                type: "day",title: "Day 5: Maasai Mara Arrival",
                timelineItems: [
                    "Private charter transfer to the Maasai Mara National Park.",
                    "Personalized check-in at a 5-star lodge.",
                    "Evening game drive."
                ],
                carouselImages1: [
                    require('../images/GoldPackageItineraryImages/image25.jpg'),
                    require('../images/GoldPackageItineraryImages/image26.jpg'),
                    require('../images/GoldPackageItineraryImages/image27.jpg')
                ],
                carouselImages2: [
                    require('../images/GoldPackageItineraryImages/image28.jpg'),
                    require('../images/GoldPackageItineraryImages/image29.jpg'),
                    require('../images/GoldPackageItineraryImages/image30.jpg')
                ]
            },
            {
                type: "day",title: "Day 6: Hot Air Balloon Safari",
                timelineItems: [
                    "Early morning wake-up call.",
                    "Sunrise hot air balloon safari over the scenic Maasai Mara.",
                    "Champagne-style bush breakfast.",
                    "Relax and unwind at the lodge.",
                    "Evening game drive."
                ],
                carouselImages1: [
                    require('../images/GoldPackageItineraryImages/image31.jpg'),
                    require('../images/GoldPackageItineraryImages/image32.jpg'),
                    require('../images/GoldPackageItineraryImages/image33.jpg')
                ],
                carouselImages2: [
                    require('../images/GoldPackageItineraryImages/image34.jpg'),
                    require('../images/GoldPackageItineraryImages/image35.jpg'),
                    require('../images/GoldPackageItineraryImages/image36.jpg')
                ]
            },
            {
                type: "day",title: "Day 7: Coast & Golf",
                timelineItems: [
                    "Private charter transfer to the coast of Kenya.",
                    "Personalized check-in at Vipingo Ridge Golf Resort.",
                    "Refresh and siesta.",
                    "Afternoon golf round."
                ],
                carouselImages1: [
                    require('../images/GoldPackageItineraryImages/image37.jpg'),
                    require('../images/GoldPackageItineraryImages/image38.jpg'),
                    require('../images/GoldPackageItineraryImages/image39.jpg')
                ],
                carouselImages2: [
                    require('../images/GoldPackageItineraryImages/image40.jpg'),
                    require('../images/GoldPackageItineraryImages/image41.jpg'),
                    require('../images/GoldPackageItineraryImages/image42.jpg')
                ]
            },
            {
                type: "day",title: "Day 8: Golf & Marine Reserve Tour",
                timelineItems: [
                    "Morning golf round.",
                    "Afternoon tour of the Kurwitu Marine Reserve.",
                    "Complimentary spa experience at the health club.",
                    "Star-lit dinner experience."
                ],
                carouselImages1: [
                    require('../images/GoldPackageItineraryImages/image43.jpg'),
                    require('../images/GoldPackageItineraryImages/image44.jpg'),
                    require('../images/GoldPackageItineraryImages/image45.jpg')
                ],
                carouselImages2: [
                    require('../images/GoldPackageItineraryImages/image46.jpg'),
                    require('../images/GoldPackageItineraryImages/image47.jpg'),
                    require('../images/GoldPackageItineraryImages/image48.jpg')
                ]
            },
            {
                type: "day",title: "Day 9: Return to Nairobi",
                timelineItems: [
                    "Early morning check-out.",
                    "Flight back to Nairobi.",
                    "VIP airport transfer.",
                    "Personalized check-in at Windsor Golf and Country Club.",
                    "Usher in the sunset with a round of golf at the club's pristine golf course."
                ],
                carouselImages1: [
                    require('../images/GoldPackageItineraryImages/image49.jpg'),
                    require('../images/GoldPackageItineraryImages/image50.jpg'),
                    require('../images/GoldPackageItineraryImages/image51.jpg')
                ],
                carouselImages2: [
                    require('../images/GoldPackageItineraryImages/image52.jpg'),
                    require('../images/GoldPackageItineraryImages/image53.jpg'),
                    require('../images/GoldPackageItineraryImages/image54.jpg')
                ]
            },
            {
                type: "day",title: "Day 10: Helicopter Safari & Golf",
                timelineItems: [
                    "Early morning helicopter safari tour over Lake Magadi.",
                    "Visit to the Giraffe Center and David Sheldrick Elephant Orphanage.",
                    "Afternoon golf round.",
                    "Dinner at the famous Carnivore restaurant."
                ],
                carouselImages1: [
                    require('../images/GoldPackageItineraryImages/image55.jpg'),
                    require('../images/GoldPackageItineraryImages/image56.jpg'),
                    require('../images/GoldPackageItineraryImages/image57.jpg')
                ],
                carouselImages2: [
                    require('../images/GoldPackageItineraryImages/image58.jpg'),
                    require('../images/GoldPackageItineraryImages/image59.jpg'),
                    require('../images/GoldPackageItineraryImages/image60.jpg')
                ]
            },
            {
                type: "day",title: "Day 11: Departure",
                timelineItems: [
                    "Check-out.",
                    "Visit to the Maasai Market.",
                    "VIP transfer to the airport for flight back home.",
                    "Check-in at the Crowne Plaza Hotel day room for rest and refreshment awaiting your flight.",
                    "Bon voyage."
                ],
                carouselImages1: [
                    require('../images/GoldPackageItineraryImages/image61.jpg'),
                    require('../images/GoldPackageItineraryImages/image62.jpg'),
                    require('../images/GoldPackageItineraryImages/image63.jpg')
                ],
                carouselImages2: [
                    require('../images/GoldPackageItineraryImages/image64.jpg'),
                    require('../images/GoldPackageItineraryImages/image65.jpg'),
                    require('../images/GoldPackageItineraryImages/image66.jpg')
                ]
            },
            {
                type: "included",title: "Included",
                timelineItems: [
                    "All park fees",
                    "All Green fees",
                    "Complimentary spa",
                    "Complimentary beverages",
                    "All meals",
                    "All transfer fees",
                    "All air charter fees"
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/included1.jpg')
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/included2.jpg')
                ]
            },
            {
                type: "excluded",title: "Excluded",
                timelineItems: [
                    "Personal insurance",
                    "Tips and gratuities",
                    "Non-complimentary drinks"
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/excluded1.jpg'),
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/excluded2.jpg')
                ]
            }
        ]
    },

    // Silver Package Itinerary
    'silver-package': {
        name: 'Silver Package',
        days: [
            {
                type: "day",title: "Day 1: Arrival & VIP Transfer",
                timelineItems: [
                    "Meet and greet at Nairobi JKIA Airport.",
                    "Transfer to a 5-star hotel.",
                    "Personalized check-in."
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/image1.jpg'),
                    require('../images/SilverPackageItineraryImages/image2.jpg'),
                    require('../images/SilverPackageItineraryImages/image3.jpg')
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/image4.jpg'),
                    require('../images/SilverPackageItineraryImages/image5.jpg'),
                    require('../images/SilverPackageItineraryImages/image6.jpg')
                ]
            },
            {
                type: "day",title: "Day 2: Game Drive & Golf",
                timelineItems: [
                    "Early morning game drive at Nairobi National Park.",
                    "Enjoy a packed bush breakfast.",
                    "Afternoon round of golf at a chosen pristine golf course in the capital city.",
                    "Dinner at the world-class Carnivore restaurant, featuring sizzling game meat platters."
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/image7.jpg'),
                    require('../images/SilverPackageItineraryImages/image8.jpg'),
                    require('../images/SilverPackageItineraryImages/image9.jpg')
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/image10.jpg'),
                    require('../images/SilverPackageItineraryImages/image11.jpg'),
                    require('../images/SilverPackageItineraryImages/image12.jpg')
                ]
            },
            {
                type: "day",title: "Day 3: Transfer to Ol Pejeta Conservancy",
                timelineItems: [
                    "Early bird breakfast and check-out.",
                    "Road transfer to Ol Pejeta Conservancy, home to the largest Black Rhino sanctuary and the Northern and Southern White Rhinos.",
                    "Select accommodation in the conservancy's luxury tented camps and lodges.",
                    "Night game drive."
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/image13.jpg'),
                    require('../images/SilverPackageItineraryImages/image14.jpg'),
                    require('../images/SilverPackageItineraryImages/image15.jpg')
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/image16.jpg'),
                    require('../images/SilverPackageItineraryImages/image17.jpg'),
                    require('../images/SilverPackageItineraryImages/image18.jpg')
                ]
            },
            {
                type: "day",title: "Day 4: Mt Kenya Golf & Conservation Experience",
                timelineItems: [
                    "Enjoy an early sunrise breakfast facing Mt Kenya.",
                    "Short drive to Fairmont Mt Kenya Golf and Safari Club.",
                    "Round of golf at the scenic course with wildlife.",
                    "Afternoon conservation experiences: dog tracking, bush and bird walks, lion tracking, meet the Northern White Rhinos.",
                    "Dinner and night game drive."
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/image19.jpg'),
                    require('../images/SilverPackageItineraryImages/image20.jpg'),
                    require('../images/SilverPackageItineraryImages/image21.jpg')
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/image22.jpg'),
                    require('../images/SilverPackageItineraryImages/image23.jpg'),
                    require('../images/SilverPackageItineraryImages/image24.jpg')
                ]
            },
            {
                type: "day",title: "Day 5: Transfer to Samburu National Park",
                timelineItems: [
                    "Early morning checkout from Ol Pejeta Conservancy.",
                    "Road transfer to Samburu National Park.",
                    "Check-in at a luxurious tented camp.",
                    "Refresh and lunch is served.",
                    "Afternoon nature trails or siesta and dip at the camp’s pool.",
                    "Evening game drive."
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/image25.jpg'),
                    require('../images/SilverPackageItineraryImages/image26.jpg'),
                    require('../images/SilverPackageItineraryImages/image27.jpg')
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/image28.jpg'),
                    require('../images/SilverPackageItineraryImages/image29.jpg'),
                    require('../images/SilverPackageItineraryImages/image30.jpg')
                ]
            },
            {
                type: "day",title: "Day 6: Samburu Experience",
                timelineItems: [
                    "Early morning game drive.",
                    "Leisure activities: visit Samburu villages, horseback safaris.",
                    "Evening game drive with bush meal and sundowner cocktail."
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/image31.jpg'),
                    require('../images/SilverPackageItineraryImages/image32.jpg'),
                    require('../images/SilverPackageItineraryImages/image33.jpg')
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/image34.jpg'),
                    require('../images/SilverPackageItineraryImages/image35.jpg'),
                    require('../images/SilverPackageItineraryImages/image36.jpg')
                ]
            },
            {
                type: "day",title: "Day 7: Transfer to The Great Rift Golf & Country Club",
                timelineItems: [
                    "Check out from Samburu National Park.",
                    "Air charter transfer to The Great Rift Golf and Country Club.",
                    "Refresh, siesta, and lunch served.",
                    "Afternoon round of golf.",
                    "Relax over a bonfire after dinner."
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/image37.jpg'),
                    require('../images/SilverPackageItineraryImages/image38.jpg'),
                    require('../images/SilverPackageItineraryImages/image39.jpg')
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/image40.jpg'),
                    require('../images/SilverPackageItineraryImages/image41.jpg'),
                    require('../images/SilverPackageItineraryImages/image42.jpg')
                ]
            },
            {
                type: "day",title: "Day 8: Golf & Hells Gate National Park",
                timelineItems: [
                    "Morning round of golf.",
                    "Afternoon excursions at Hell's Gate National Park.",
                    "Evening relaxation at the club lounge."
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/image43.jpg'),
                    require('../images/SilverPackageItineraryImages/image44.jpg'),
                    require('../images/SilverPackageItineraryImages/image45.jpg')
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/image46.jpg'),
                    require('../images/SilverPackageItineraryImages/image47.jpg'),
                    require('../images/SilverPackageItineraryImages/image48.jpg')
                ]
            },
            {
                type: "day",title: "Day 9: Golf & Crater Lake",
                timelineItems: [
                    "Morning round of golf.",
                    "Afternoon visit to the Crater Lake."
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/image49.jpg'),
                    require('../images/SilverPackageItineraryImages/image50.jpg'),
                    require('../images/SilverPackageItineraryImages/image51.jpg')
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/image52.jpg'),
                    require('../images/SilverPackageItineraryImages/image53.jpg'),
                    require('../images/SilverPackageItineraryImages/image54.jpg')
                ]
            },
            {
                type: "day",title: "Day 10: Return to Nairobi & Golf",
                timelineItems: [
                    "Road transfer back to Nairobi.",
                    "Personalized check-in at The Karen Country and Golf Club.",
                    "Refresh and relax, lunch is served.",
                    "Afternoon complimentary spa experience or round of golf."
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/image55.jpg'),
                    require('../images/SilverPackageItineraryImages/image56.jpg'),
                    require('../images/SilverPackageItineraryImages/image57.jpg')
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/image58.jpg'),
                    require('../images/SilverPackageItineraryImages/image59.jpg'),
                    require('../images/SilverPackageItineraryImages/image60.jpg')
                ]
            },
            {
                type: "day",title: "Day 11: Departure",
                timelineItems: [
                    "Early breakfast and checkout.",
                    "Visit to the Giraffe Centre and Maasai Market.",
                    "Transfer to Jomo Kenyatta International Airport for flight back home.",
                    "Relax at the complimentary day room at Hilton Garden Inn awaiting your flight.",
                    "Bon voyage."
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/image61.jpg'),
                    require('../images/SilverPackageItineraryImages/image62.jpg'),
                    require('../images/SilverPackageItineraryImages/image63.jpg')
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/image64.jpg'),
                    require('../images/SilverPackageItineraryImages/image65.jpg'),
                    require('../images/SilverPackageItineraryImages/image66.jpg')
                ]
            },
            {
                type: "included",title: "Included",
                timelineItems: [
                    "All park fees",
                    "All Green fees",
                    "Complimentary spa",
                    "Complimentary beverages",
                    "All meals",
                    "All transfer fees",
                    "All air charter fees"
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/included1.jpg')
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/included2.jpg')
                ]
            },
            {
                type: "excluded",title: "Excluded",
                timelineItems: [
                    "Personal insurance",
                    "Tips and gratuities",
                    "Non-complimentary drinks"
                ],
                carouselImages1: [
                    require('../images/SilverPackageItineraryImages/excluded1.jpg'),
                ],
                carouselImages2: [
                    require('../images/SilverPackageItineraryImages/excluded2.jpg')
                ]
            }
        ]
    },

    // Zanzibar Package Itinerary
    'zanzibar-package': {
        name: 'Zanzibar Package',
        days: [
            {
                type: "day",title: "Day 1: Arrival & Stay in Nairobi",
                timelineItems: [
                    "Arrival at JKIA Airport, Nairobi, Kenya.",
                    "Meet and Greet, transfer to a 5-star hotel of choice.",
                    "Overnight stay in Nairobi."
                ],
                carouselImages1: [
                    require('../images/ZanzibarPackageItineraryImages/image1.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image2.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image3.jpg')
                ],
                carouselImages2: [
                    require('../images/ZanzibarPackageItineraryImages/image4.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image5.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image6.jpg')
                ]
            },
            {
                type: "day",title: "Day 2: Flight to Zanzibar & Exploration",
                timelineItems: [
                    "Early morning check out.",
                    "Transfer to JKIA for international flight to Zanzibar.",
                    "Meet and greet in Zanzibar and VIP transfer to Zanzibar Golf Resort.",
                    "Freshen up, siesta, lunch at the resort.",
                    "Evening town walk to explore the sights and sounds of Old Town."
                ],
                carouselImages1: [
                    require('../images/ZanzibarPackageItineraryImages/image7.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image8.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image9.jpg')
                ],
                carouselImages2: [
                    require('../images/ZanzibarPackageItineraryImages/image10.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image11.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image12.jpg')
                ]
            },
            {
                type: "day",title: "Day 3: Golf & The Rock Restaurant",
                timelineItems: [
                    "Early morning breakfast.",
                    "Morning golf round at the resort.",
                    "Lunch and siesta.",
                    "Afternoon golf round.",
                    "Dinner at the famed The Rock Restaurant."
                ],
                carouselImages1: [
                    require('../images/ZanzibarPackageItineraryImages/image13.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image14.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image15.jpg')
                ],
                carouselImages2: [
                    require('../images/ZanzibarPackageItineraryImages/image16.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image17.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image18.jpg')
                ]
            },
            {
                type: "day",title: "Day 4: Excursions & Golf",
                timelineItems: [
                    "Early morning breakfast.",
                    "Choice of sea or island excursions in the morning.",
                    "Lunch at the Beach House Zanzibar for authentic Zanzibari cuisine.",
                    "Afternoon golf at the resort.",
                    "Last night in Zanzibar."
                ],
                carouselImages1: [
                    require('../images/ZanzibarPackageItineraryImages/image19.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image20.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image21.jpg')
                ],
                carouselImages2: [
                    require('../images/ZanzibarPackageItineraryImages/image22.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image23.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image24.jpg')
                ]
            },
            {
                type: "day",title: "Day 5: Departure & Nairobi Excursions",
                timelineItems: [
                    "Breakfast and early morning check out.",
                    "VIP transfer to Zanzibar Airport for onward international flight to Nairobi.",
                    "Arrival at JKIA Nairobi and transfer to a 5-star hotel day room.",
                    "Refresh and enjoy an excursion to either The Nairobi National Park Animal Orphanage, The Giraffe Centre, or Maasai Curio Market.",
                    "Back to the hotel, followed by VIP transfer to JKIA for onward international departure flight."
                ],
                carouselImages1: [
                    require('../images/ZanzibarPackageItineraryImages/image25.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image26.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image27.jpg')
                ],
                carouselImages2: [
                    require('../images/ZanzibarPackageItineraryImages/image28.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image29.jpg'),
                    require('../images/ZanzibarPackageItineraryImages/image30.jpg')
                ]
            },
            {
                type: "included",title: "Included",
                timelineItems: [
                    "All park fees",
                    "All Green fees",
                    "Complimentary spa",
                    "Complimentary beverages",
                    "All meals",
                    "All transfer fees",
                    "All air charter fees"
                ],
                carouselImages1: [
                    require('../images/ZanzibarPackageItineraryImages/included1.jpg')
                ],
                carouselImages2: [
                    require('../images/ZanzibarPackageItineraryImages/included2.jpg')
                ]
            },
            {
                type: "day",title: "Excluded: Excluded",
                timelineItems: [
                    "Personal insurance",
                    "Tips and gratuities",
                    "Non-complimentary drinks"
                ],
                carouselImages1: [
                    require('../images/ZanzibarPackageItineraryImages/excluded1.jpg'),
                ],
                carouselImages2: [
                    require('../images/ZanzibarPackageItineraryImages/excluded2.jpg')
                ]
            }
        ]
    },

    // Rwanda Package Itinerary
    'rwanda-package': {
        name: 'Rwanda Package',
        days: [
            {
                type: "day",title: "Day 1: Arrival & VVIP Transfer",
                timelineItems: [
                    "Arrival at Kigali International Airport.",
                    "Meet and Greet by the LuxOdyssey team.",
                    "VVIP transfer to a 5-star hotel."
                ],
                carouselImages1: [
                    require('../images/RwandaPackageItineraryImages/image1.jpg'),
                    require('../images/RwandaPackageItineraryImages/image2.jpg'),
                    require('../images/RwandaPackageItineraryImages/image3.jpg')
                ],
                carouselImages2: [
                    require('../images/RwandaPackageItineraryImages/image4.jpg'),
                    require('../images/RwandaPackageItineraryImages/image5.jpg'),
                    require('../images/RwandaPackageItineraryImages/image6.jpg')
                ]
            },
            {
                type: "day",title: "Day 2: Golf & City Tour",
                timelineItems: [
                    "Early breakfast at the hotel.",
                    "Transfer to Kigali Golf Resort and Villas.",
                    "Morning 18-hole round of golf surrounded by wildlife and large greens.",
                    "Hearty African cuisine lunch at the resort.",
                    "Transfer back to the hotel for refreshment and an afternoon siesta.",
                    "Late afternoon tour of Kigali, visiting famed sites.",
                    "Overnight stay at the hotel."
                ],
                carouselImages1: [
                    require('../images/RwandaPackageItineraryImages/image7.jpg'),
                    require('../images/RwandaPackageItineraryImages/image8.jpg'),
                    require('../images/RwandaPackageItineraryImages/image9.jpg')
                ],
                carouselImages2: [
                    require('../images/RwandaPackageItineraryImages/image10.jpg'),
                    require('../images/RwandaPackageItineraryImages/image11.jpg'),
                    require('../images/RwandaPackageItineraryImages/image12.jpg')
                ]
            },
            {
                type: "day",title: "Day 3: Transfer to Vintage Cottages & Golf",
                timelineItems: [
                    "Early breakfast and check out.",
                    "VIP transfer to Vintage Cottages by road.",
                    "Personalized check-in.",
                    "Refresh, lunch is served.",
                    "Afternoon round of golf at Falcon Golf and Country Club.",
                    "Overnight stay at Vintage Cottages."
                ],
                carouselImages1: [
                    require('../images/RwandaPackageItineraryImages/image13.jpg'),
                    require('../images/RwandaPackageItineraryImages/image14.jpg'),
                    require('../images/RwandaPackageItineraryImages/image15.jpg')
                ],
                carouselImages2: [
                    require('../images/RwandaPackageItineraryImages/image16.jpg'),
                    require('../images/RwandaPackageItineraryImages/image17.jpg'),
                    require('../images/RwandaPackageItineraryImages/image18.jpg')
                ]
            },
            {
                type: "day",title: "Day 4: Lake Muhazi & Akagera National Park",
                timelineItems: [
                    "Early morning breakfast and checkout.",
                    "Private boat trip and bird watching at Lake Muhazi.",
                    "4x4 road transfer to Akagera National Park.",
                    "VIP check-in at Mantis Akagera Game Lodge.",
                    "Overnight stay at the lodge."
                ],
                carouselImages1: [
                    require('../images/RwandaPackageItineraryImages/image19.jpg'),
                    require('../images/RwandaPackageItineraryImages/image20.jpg'),
                    require('../images/RwandaPackageItineraryImages/image21.jpg')
                ],
                carouselImages2: [
                    require('../images/RwandaPackageItineraryImages/image22.jpg'),
                    require('../images/RwandaPackageItineraryImages/image23.jpg'),
                    require('../images/RwandaPackageItineraryImages/image24.jpg')
                ]
            },
            {
                type: "day",title: "Day 5: Akagera National Park Safari",
                timelineItems: [
                    "Early morning breakfast.",
                    "Full day 4x4 game drive at Akagera National Park.",
                    "Opportunity to spot the Big Five.",
                    "Picnic lunch at the park.",
                    "Return to the lodge for rest, dinner, and overnight stay."
                ],
                carouselImages1: [
                    require('../images/RwandaPackageItineraryImages/image25.jpg'),
                    require('../images/RwandaPackageItineraryImages/image26.jpg'),
                    require('../images/RwandaPackageItineraryImages/image27.jpg')
                ],
                carouselImages2: [
                    require('../images/RwandaPackageItineraryImages/image28.jpg'),
                    require('../images/RwandaPackageItineraryImages/image29.jpg'),
                    require('../images/RwandaPackageItineraryImages/image30.jpg')
                ]
            },
            {
                type: "day",title: "Day 6: Departure",
                timelineItems: [
                    "Breakfast at the lodge.",
                    "Check out and transfer to Kigali International Airport for onward departure flight."
                ],
                carouselImages1: [
                    require('../images/RwandaPackageItineraryImages/image31.jpg'),
                    require('../images/RwandaPackageItineraryImages/image32.jpg'),
                    require('../images/RwandaPackageItineraryImages/image33.jpg')
                ],
                carouselImages2: [
                    require('../images/RwandaPackageItineraryImages/image34.jpg'),
                    require('../images/RwandaPackageItineraryImages/image35.jpg'),
                    require('../images/RwandaPackageItineraryImages/image36.jpg')
                ]
            },
            {
                type: "included",title: "Included",
                timelineItems: [
                    "All park fees",
                    "All Green fees",
                    "Complimentary spa",
                    "Complimentary beverages",
                    "All meals",
                    "All transfer fees",
                    "All air charter fees"
                ],
                carouselImages1: [
                    require('../images/RwandaPackageItineraryImages/included1.jpg')
                ],
                carouselImages2: [
                    require('../images/RwandaPackageItineraryImages/included2.jpg')
                ]
            },
            {
                type: "day",title: "Excluded: Excluded",
                timelineItems: [
                    "Personal insurance",
                    "Tips and gratuities",
                    "Non-complimentary drinks"
                ],
                carouselImages1: [
                    require('../images/RwandaPackageItineraryImages/excluded1.jpg'),
                ],
                carouselImages2: [
                    require('../images/RwandaPackageItineraryImages/excluded2.jpg')
                ]
            }
        ]
    },

    // Dubai Package Itinerary
    'dubai-package': {
        name: 'Dubai Package',
        days: [
            {
                type: "day",title: "Day 1: Arrival in Dubai",
                timelineItems: [
                    "Meet and Greet at Dubai International Airport by LuxOdyssey team.",
                    "VIP transfer to a 5-star hotel.",
                    "Personalised check-in."
                ],
                carouselImages1: [
                    require('../images/DubaiPackageItineraryImages/image1.jpg'),
                    require('../images/DubaiPackageItineraryImages/image2.jpg'),
                    require('../images/DubaiPackageItineraryImages/image3.jpg')
                ],
                carouselImages2: [
                    require('../images/DubaiPackageItineraryImages/image4.jpg'),
                    require('../images/DubaiPackageItineraryImages/image5.jpg'),
                    require('../images/DubaiPackageItineraryImages/image6.jpg')
                ]
            },
            {
                type: "day",title: "Day 2: Dubai Sightseeing & Jumeirah Golf Club",
                timelineItems: [
                    "Breakfast served.",
                    "City sightseeing tour covering sights and sounds of Dubai.",
                    "Lunch at Bur Dubai to experience its historical charm.",
                    "Refresh and siesta at the hotel.",
                    "First golf round at Jumeirah Golf Club in the evening.",
                    "Evening walk at the Dubai Marina and cocktails at a rooftop lounge."
                ],
                carouselImages1: [
                    require('../images/DubaiPackageItineraryImages/image7.jpg'),
                    require('../images/DubaiPackageItineraryImages/image8.jpg'),
                    require('../images/DubaiPackageItineraryImages/image9.jpg')
                ],
                carouselImages2: [
                    require('../images/DubaiPackageItineraryImages/image10.jpg'),
                    require('../images/DubaiPackageItineraryImages/image11.jpg'),
                    require('../images/DubaiPackageItineraryImages/image12.jpg')
                ]
            },
            {
                type: "day",title: "Day 3: Dubai Creek Golf & JW Marriott",
                timelineItems: [
                    "Transfer to JW Marriott and personalised check-in.",
                    "Golf round at Dubai Creek Golf & Yacht Club.",
                    "Lunch at the club.",
                    "Evening Dubai food tours or optional night out at Top Golf Dubai."
                ],
                carouselImages1: [
                    require('../images/DubaiPackageItineraryImages/image13.jpg'),
                    require('../images/DubaiPackageItineraryImages/image14.jpg'),
                    require('../images/DubaiPackageItineraryImages/image15.jpg')
                ],
                carouselImages2: [
                    require('../images/DubaiPackageItineraryImages/image16.jpg'),
                    require('../images/DubaiPackageItineraryImages/image17.jpg'),
                    require('../images/DubaiPackageItineraryImages/image18.jpg')
                ]
            },
            {
                type: "day",title: "Day 4: Emirates Golf Club & Desert Safari",
                timelineItems: [
                    "Golf round at Emirates Golf Club.",
                    "Lunch at the club.",
                    "Afternoon siesta or a dip in the luxurious pools.",
                    "Evening desert safari with dune bashing, camel rides, live entertainment, and BBQ buffet dinner."
                ],
                carouselImages1: [
                    require('../images/DubaiPackageItineraryImages/image19.jpg'),
                    require('../images/DubaiPackageItineraryImages/image20.jpg'),
                    require('../images/DubaiPackageItineraryImages/image21.jpg')
                ],
                carouselImages2: [
                    require('../images/DubaiPackageItineraryImages/image22.jpg'),
                    require('../images/DubaiPackageItineraryImages/image23.jpg'),
                    require('../images/DubaiPackageItineraryImages/image24.jpg')
                ]
            },
            {
                type: "day",title: "Day 5: Dubai Hills Golf Club & City Tour",
                timelineItems: [
                    "Golf round at Dubai Hills Golf Club.",
                    "Afternoon city tour and excursions (including Palm Jumeirah).",
                    "Evening Dubai walk and night tour with Big Bus."
                ],
                carouselImages1: [
                    require('../images/DubaiPackageItineraryImages/image25.jpg'),
                    require('../images/DubaiPackageItineraryImages/image26.jpg'),
                    require('../images/DubaiPackageItineraryImages/image27.jpg')
                ],
                carouselImages2: [
                    require('../images/DubaiPackageItineraryImages/image28.jpg'),
                    require('../images/DubaiPackageItineraryImages/image29.jpg'),
                    require('../images/DubaiPackageItineraryImages/image30.jpg')
                ]
            },
            {
                type: "day",title: "Day 6: Transfer to Abu Dhabi & Night Golf",
                timelineItems: [
                    "Early morning check-out and VIP transfer to Abu Dhabi.",
                    "Personalised check-in at Vogo Abu Dhabi Golf Resort & Spa.",
                    "Night golf at the resort."
                ],
                carouselImages1: [
                    require('../images/DubaiPackageItineraryImages/image31.jpg'),
                    require('../images/DubaiPackageItineraryImages/image32.jpg'),
                    require('../images/DubaiPackageItineraryImages/image33.jpg')
                ],
                carouselImages2: [
                    require('../images/DubaiPackageItineraryImages/image34.jpg'),
                    require('../images/DubaiPackageItineraryImages/image35.jpg'),
                    require('../images/DubaiPackageItineraryImages/image36.jpg')
                ]
            },
            {
                type: "day",title: "Day 7: Golf & Excursions in Abu Dhabi",
                timelineItems: [
                    "Golf round at the resort.",
                    "Afternoon excursions exploring Abu Dhabi’s history.",
                    "Complimentary spa experience."
                ],
                carouselImages1: [
                    require('../images/DubaiPackageItineraryImages/image37.jpg'),
                    require('../images/DubaiPackageItineraryImages/image38.jpg'),
                    require('../images/DubaiPackageItineraryImages/image39.jpg')
                ],
                carouselImages2: [
                    require('../images/DubaiPackageItineraryImages/image40.jpg'),
                    require('../images/DubaiPackageItineraryImages/image41.jpg'),
                    require('../images/DubaiPackageItineraryImages/image42.jpg')
                ]
            },
            {
                type: "day",title: "Day 8: Ras Al Khaimah & Golf",
                timelineItems: [
                    "VIP road transfer to Ras Al Khaimah.",
                    "Personalised check-in at Al Hamra Village Hotel.",
                    "Refresh, lunch at the resort.",
                    "Afternoon golf round."
                ],
                carouselImages1: [
                    require('../images/DubaiPackageItineraryImages/image43.jpg'),
                    require('../images/DubaiPackageItineraryImages/image44.jpg'),
                    require('../images/DubaiPackageItineraryImages/image45.jpg')
                ],
                carouselImages2: [
                    require('../images/DubaiPackageItineraryImages/image46.jpg'),
                    require('../images/DubaiPackageItineraryImages/image47.jpg'),
                    require('../images/DubaiPackageItineraryImages/image48.jpg')
                ]
            },
            {
                type: "day",title: "Day 9: Ras Al Khaimah Golf & Cultural Exploration",
                timelineItems: [
                    "Morning golf round.",
                    "Afternoon cultural exploration of Ras Al Khaimah."
                ],
                carouselImages1: [
                    require('../images/DubaiPackageItineraryImages/image49.jpg'),
                    require('../images/DubaiPackageItineraryImages/image50.jpg'),
                    require('../images/DubaiPackageItineraryImages/image51.jpg')
                ],
                carouselImages2: [
                    require('../images/DubaiPackageItineraryImages/image52.jpg'),
                    require('../images/DubaiPackageItineraryImages/image53.jpg'),
                    require('../images/DubaiPackageItineraryImages/image54.jpg')
                ]
            },
            {
                type: "day",title: "Day 10: Return to Dubai & Arabian Golf Ranches",
                timelineItems: [
                    "Road transfer back to Dubai.",
                    "Personalised check-in at the Arabian Golf Ranches.",
                    "Refresh, siesta, and lunch.",
                    "Final golf round in UAE.",
                    "Complimentary spa experience."
                ],
                carouselImages1: [
                    require('../images/DubaiPackageItineraryImages/image55.jpg'),
                    require('../images/DubaiPackageItineraryImages/image56.jpg'),
                    require('../images/DubaiPackageItineraryImages/image57.jpg')
                ],
                carouselImages2: [
                    require('../images/DubaiPackageItineraryImages/image58.jpg'),
                    require('../images/DubaiPackageItineraryImages/image59.jpg'),
                    require('../images/DubaiPackageItineraryImages/image60.jpg')
                ]
            },
            {
                type: "day",title: "Day 11: Departure",
                timelineItems: [
                    "VIP transfer to Dubai International Airport for flight back home.",
                    "Bon Voyage!"
                ],
                carouselImages1: [
                    require('../images/DubaiPackageItineraryImages/image61.jpg'),
                    require('../images/DubaiPackageItineraryImages/image62.jpg'),
                    require('../images/DubaiPackageItineraryImages/image63.jpg')
                ],
                carouselImages2: [
                    require('../images/DubaiPackageItineraryImages/image64.jpg'),
                    require('../images/DubaiPackageItineraryImages/image65.jpg'),
                    require('../images/DubaiPackageItineraryImages/image66.jpg')
                ]
            },
            {
                type: "included",title: "Included",
                timelineItems: [
                    "All park fees",
                    "All Green fees",
                    "Complimentary spa",
                    "Complimentary beverages",
                    "All meals",
                    "All transfer fees",
                    "All air charter fees"
                ],
                carouselImages1: [
                    require('../images/DubaiPackageItineraryImages/included1.jpg')
                ],
                carouselImages2: [
                    require('../images/DubaiPackageItineraryImages/included2.jpg')
                ]
            },
            {
                type: "excluded",title: "Excluded",
                timelineItems: [
                    "Personal insurance",
                    "Tips and gratuities",
                    "Non-complimentary drinks"
                ],
                carouselImages1: [
                    require('../images/DubaiPackageItineraryImages/excluded1.jpg'),
                ],
                carouselImages2: [
                    require('../images/DubaiPackageItineraryImages/excluded2.jpg')
                ]
            }
        ]
    }
};

export default itineraries;
