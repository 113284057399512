import dubaiItinerary from '../images/Itineraries/dubaiItinerary.jpg'
import zanzibarItinerary from '../images/Itineraries/zanzibarItinerary.jpg'
import rwandaItrinerary from '../images/Itineraries/rwandaItrinerary.jpg'
import goldItinerary from '../images/Itineraries/goldItinerary.jpg'
import silverItinerary from '../images/Itineraries/silverItinerary.jpg'
import nairobiCircuit from '../images/Circuits/nairobiCircuit.jpg'
import mtKenyaCircuit from '../images/Circuits/mtKenyaCircuit.jpg'
import greatRiftValleyCircuit from '../images/Circuits/greatRiftValleyCircuit.jpg'
import coastalCircuit from '../images/Circuits/coastalCircuit.jpg'
import rwandaCircuit from '../images/Circuits/rwandaCircuit.jpg'
import karenCountryAndGolfClub from '../images/GolfCourses/karenCountryAndGolfClub.jpg'
import windsorGolfHotelandCountryClub from '../images/GolfCourses/windsorGolfHotelandCountryClub.jpg'
import fairmontMtKenyaSafariClub from '../images/GolfCourses/fairmontMtKenyaSafariClub.jpg'
import theGreatRiftValleyLodgeAndGolfResort from '../images/GolfCourses/theGreatRiftValleyLodgeAndGolfResort.jpg'
import diamondsLeisureBeachAndGolfResort from '../images/GolfCourses/diamondsLeisureBeachAndGolfResort.jpg'
import hellsGateNationalPark from '../images/Excursions/hellsGateNationalPark.jpg'
import solioConservancy from '../images/Excursions/solioConservancy.jpg'
import mugieConservancy from '../images/Excursions/mugieConservancy.jpg'
import giraffeCentre from '../images/Excursions/giraffeCentre.jpg'
import nationalMuseumOfKenya from '../images/Excursions/nationalMuseumOfKenya.jpg'
import theResidencesAtKaren from '../images/Hotels/theResidencesAtKaren.jpg'
import nairobiSerena from '../images/Hotels/nairobiSerena.jpg'
import theArk from '../images/Hotels/theArk.jpg'
import almanaraLuxuryBoutiqueHotelAndVillas from '../images/Hotels/almanaraLuxuryBoutiqueHotelAndVillas.jpg'
import MaasaiMaraHotels from '../images/Hotels/MaasaiMaraHotels.jpg'


  export const data = [
  {
        "id": 1,
        "category": "Itineraries",
        "product": "Dubai Package", // Removed the trailing comma
        "image": dubaiItinerary,
        "desc": "Dubai Itinerary 7 days",
        "price": 2000,
        "star": 5
      },
      {
        "id": 2,
        "category":"Itineraries",
        "product": "Zanzibar Package",
        "image": zanzibarItinerary,
        "desc": "Zanzibar Itinerary 7 days",
        "price": 1500,
        "star": 5
      },
      {
        "id": 3,
        "category":"Itineraries",
        "product": "Rwanda Package",
        "image": rwandaItrinerary,
        "desc": "Rwanda Itinerary 7 days",
        "price": 1300,
        "star": 5
      },
      {
        "id": 4,
        "category":"Itineraries",
        "product": "Gold Package",
        "image": goldItinerary,
        "desc": "Gold Package 7 days",
        "price": 1500,
        "star": 5
      },
      {
        "id": 5,
        "category":"Itineraries",
        "product": "Silver Package",
        "image": silverItinerary,
        "desc": "Silver Package 7 days",
        "price": 800,
        "star": 5
      },
      {
        "id": 6,
        "category":"Itineraries",
        "product": "Silver 2 Package",
        "image": silverItinerary,
        "desc": "Silver Package 7 days",
        "price": 1600,
        "star": 5
      },
      {
        "id": 7,
        "category":"Itineraries",
        "product": "Silver 3 Package",
        "image": silverItinerary,
        "desc": "Silver Package 7 days",
        "price": 1600,
        "star": 5
      },


      
      {
        id: 6,
         "category":"Circuits",
         "product": "Nairobi City Circuit",
         "image": nairobiCircuit,
         "desc": "Explore golfing, excursions & accomodation in Nairobi Circuit",
         "star": 5
       },
       {
         id: 7,
         "category":"Circuits",
         "product": "Mt Kenya Circuit",
         "image": mtKenyaCircuit,
         "desc": "Explore golfing, excursions & accomodation in Mt.kenya Circuit",
         "star": 5
       },
       {
         id: 8,
         "category":"Circuits",
         "product": "Great Rift Valley Circuit",
         "image": greatRiftValleyCircuit,
         "desc": "Explore golfing, excursions & accomodation in the Great Rift Valley Circuit",
         "star": 5
       },
       {
         id: 9,
         "category":"Circuits",
         "product": "Coastal Circuit",
         "image": coastalCircuit,
         "desc": "Explore golfing, excursions & accomodation in the Coastal Circuit",
         "star": 5
       },
       {
         id: 10,
         "category":"Circuits",
         "product": "Rwanda Circuit",
         "image": rwandaCircuit,
         "desc": "Explore golfing, excursions & accomodation in Rwanda Circuit",
         "star": 5
       },



       {
        id: 11,
         "category":"Golf Courses",
         "product": "Karen Country and Golf Club",
         "image": karenCountryAndGolfClub,
         "desc": "Explore Karen Country and Golf Club",
         "star": 5
       },
       {
         id: 12,
         "category":"Golf Courses",
         "product": "Windsor Golf Hotel and Country Club",
         "image": windsorGolfHotelandCountryClub,
         "desc": "Explore Windsor Golf Hotel and Country Club",
         "star": 5
       },
       {
         id: 13,
         "category":"Golf Courses",
         "product": "Fairmont Mt Kenya Safari Club",
         "image": fairmontMtKenyaSafariClub,
         "desc": "Explore Fairmont Mt Kenya Safari Club",
         "star": 5
       },
       {
         id: 14,
         "category":"Golf Courses",
         "product": "The Great Rift Valley Lodge and Golf Resort",
         "image": theGreatRiftValleyLodgeAndGolfResort,
         "desc": "Explore The Great Rift Valley Lodge and Golf Resort",
         "star": 5
       },
       {
         id: 15,
         "category":"Golf Courses",
         "product": "Diamonds Leisure Beach and Golf Resort",
         "image": diamondsLeisureBeachAndGolfResort,
         "desc": "Explore Diamonds Leisure Beach and Golf Resort",
         "star": 5
       },



       {
        id: 16,
         "category":"Excursions",
         "product": "Hells Gate National Park",
         "image": hellsGateNationalPark,
         "desc": "Explore Hells Gate National Park",
         "star": 5
       },
       {
         id: 17,
         "category":"Excursions",
         "product": "Solio Conservancy",
         "image": solioConservancy,
         "desc": "Explore Solio Conservancy",
         "star": 5
       },
       {
         id: 18,
         "category":"Excursions",
         "product": "Mugie Conservancy",
         "image": mugieConservancy,
         "desc": "Explore Mugie Conservancy",
         "star": 5
       },
       {
         id: 19,
         "category":"Excursions",
         "product": "Giraffe Centre",
         "image": giraffeCentre,
         "desc": "Explore Giraffe Centre",
         "star": 5
       },
       {
        id: 20,
         "category":"Excursions",
         "product": "National Museum Of Kenya",
         "image": nationalMuseumOfKenya,
         "desc": "Explore The National Museum Of Kenya",
         "star": 5
       }, 


       {
        id: 21,
         "category":"Hotels",
         "product": "The Residences At Karen",
         "image": theResidencesAtKaren,
         "desc": "Explore The Residences At Karen",
         "star": 5
       },
       {
         id: 22,
         "category":"Hotels",
         "product": "Nairobi Serena Hotel",
         "image": nairobiSerena,
         "desc": "Explore The Nairobi Serena Hotel",
         "star": 5
       },
       {
         id: 23,
         "category":"Hotels",
         "product": "The Ark Hotel",
         "image": theArk,
         "desc": "Explore The Ark Hotel",
         "star": 5
       },
       {
         id: 24,
         "category":"Hotels",
         "product": "Almanara Luxury Boutique Hotel and Villas",
         "image": almanaraLuxuryBoutiqueHotelAndVillas,
         "desc": "Explore The Almanara Luxury Boutique Hotel & Villas",
         "star": 5
       },
       {
         id: 25,
         "category":"Hotels",
         "product": "Maasai Mara Hotels",
         "image": MaasaiMaraHotels,
         "desc": "Explore the Maasai Mara Hotels",
         "star": 5
       } 

]
