// src/Components/LuxOdysseyBusinesses.jsx

import React from 'react';
import '../Style/LuxOdysseyBusinesses.css';
import { images } from '../Library/images';
import { Box } from '@mui/material';

// Smooth scroll function
function scrollToSection(id) {
    const element = document.getElementById(id);
    if (element) {
        window.scrollTo({
            top: element.offsetTop - 100, // Adjust for any fixed header height
            behavior: 'smooth',
        });
    }
}

function LuxOdysseyBusinesses() {
    return (
        <Box className="luxBusinesses">
            <Box className="business" onClick={() => scrollToSection('itineraries')}>
                <img className="business_image" src={images.LuxItineraries} alt="Lux Itineraries" />
                <span>Itineraries</span>
            </Box>
            <Box className="business" onClick={() => scrollToSection('circuits')}>
                <img className="business_image" src={images.LuxCircuits} alt="Lux Circuits" />
                <span>Circuits</span>
            </Box>
            <Box className="business" onClick={() => scrollToSection('golf-courses')}>
                <img className="business_image" src={images.LuxGolfCourses} alt="Lux Golf Courses" />
                <span>Golf Courses</span>
            </Box>
            <Box className="business" onClick={() => scrollToSection('excursions')}>
                <img className="business_image" src={images.LuxExcursions} alt="Lux Excursions" />
                <span>Excursions</span>
            </Box>
            <Box className="business" onClick={() => scrollToSection('hotels')}>
                <img className="business_image" src={images.LuxHotels} alt="Lux Hotels" />
                <span>Hotels</span>
            </Box>
        </Box>
    );
}

export default LuxOdysseyBusinesses;
