import React from "react";
import '../Style/ItinerarySearchForm.css';
import SearchIcon from '@mui/icons-material/Search';

const ItinerarySearchForm = () => {
  return (
    <div className="container10">
      <div className="trip-section">
        <div className="trip-container">
          
          <h1 className="main-heading">Choose Your Trip</h1>
          <p className="description">
            Looking for your dream vacation destination but don’t know where to start? Let us help you craft the perfect, unforgettable escape to your dream vacation.
          </p>
        </div>
      </div>

      <div className="search-bar">
        <div className="search-option">
          <div className="option-label">Where</div>
          <select id="destination-dropdown">
            <option value="">Select Destination</option>
            <option value="Kenya">Kenya</option>
            <option value="Rwanda">Rwanda</option>
            <option value="UAE">UAE</option>
            <option value="USA">USA</option>
            <option value="Zanzibar">Zanzibar</option>
            <option value="Tanzania">Tanzania</option>
          </select>
        </div>
        <div className="divider"></div>
        <div className="search-option">
          <div className="option-label">From Date</div>
          <input type="date" />
        </div>
        <div className="divider"></div>
        <div className="search-option">
          <div className="option-label">To Date</div>
          <input type="date" />
        </div>
        <div className="divider"></div>
        <div className="search-option">
          <div className="option-label">Guests</div>
          <select id="guests-dropdown">
            <option value="">Select Guests</option>
            <option value="adults">Adults</option>
            <option value="youth">Youth (12-17)</option>
            <option value="children">Children (3-11)</option>
            <option value="toddlers">Toddlers (0-2)</option>
          </select>
        </div>
      </div>

      <div className="itinerary-search-bar">
        <div className="search-option">
          <div className="option-label">Activities</div>
          <select id="activities-dropdown">
            <option value="">Select Activities</option>
            <option value="golf">Golf</option>
            <option value="safari">Safari</option>
            <option value="heli-tour">Heli Tour</option>
            <option value="beach">Beach</option>
          </select>
        </div>
        <div className="divider"></div>
        <div className="search-option">
          <div className="option-label">No. of Rooms</div>
          <select id="rooms-dropdown">
            <option value="">Select Room Type</option>
            <option value="presidential">Presidential</option>
            <option value="honeymoon">Honeymoon Suite</option>
            <option value="penthouse">Penthouse</option>
            <option value="junior-suite">Junior Suite</option>
            <option value="suite">Suite</option>
            <option value="single-room">Single Room</option>
            <option value="double-room">Double Room</option>
            <option value="cabana">Cabana</option>
          </select>
        </div>
        <div className="divider"></div>
        <div className="search-option">
          <div className="option-label">Transfer Mode</div>
          <select id="transport-dropdown">
            <option value="">Select Transport</option>
            <option value="car">Car</option>
            <option value="air">Air</option>
          </select>
        </div>
        <div className="divider"></div>
        <div className="search-option-last">
          <div className="search-button">
            <span className="search-icon"><SearchIcon /></span> Search Itinerary
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItinerarySearchForm;
