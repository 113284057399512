// src/components/Product.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useStateValue } from '../ContextAPI/StateProvider';
import { NumericFormat } from 'react-number-format';
import star from '../images/lux_images/star_yellow.png';
import '../Style/Product.css';

const Product = ({ product }) => {
    const [, dispatch] = useStateValue();

    const addToBasket = () => {
        if (!product) return;
        dispatch({
            type: 'ADD_TO_BASKET',
            item: {
                id: product.id,
                title: product.product,
                image: product.image,
                price: product.price,
                rating: product.star,
            },
        });
    };

    if (!product) {
        return <div className="product_error">Product data is unavailable.</div>;
    }

    return (
        <Box className="product">
            <Link
                to={`/itinerary/${product.product?.toLowerCase().replace(/ /g, '-')}`}
                style={{ textDecoration: 'none' }}
            >
                <Box>
                    <LazyLoadImage
                        src={product.image || ''}
                        alt={product.product || 'Product'}
                        effect="blur"
                        height="200px"
                        width="100%"
                    />
                    <Box className="product_info">
                        <Typography className="title">{product.product}</Typography>
                        <Typography className="product_price">
                            <NumericFormat
                                value={product.price}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'USD P_P_D: '}
                                renderText={(formattedPrice) => <span>{formattedPrice}</span>}
                            />
                        </Typography>
                        <Box className="product_rating">
                            {Array(product.star || 0)
                                .fill()
                                .map((_, index) => (
                                    <img key={index} src={star} alt="Star" />
                                ))}
                        </Box>
                    </Box>
                </Box>
            </Link>
            <Button className="button" onClick={addToBasket}>
                <p className="click_button">ADD TO CART</p>
            </Button>
        </Box>
    );
};

export default Product;
