import logo from '../images/lux_images/1.png';
import homeImage from '../images/lux_images/Holiday-Savings-Slider-4.jpg';
import contactUs from '../images/lux_images/contact.png';
import aboutUs from '../images/lux_images/aboutus.png';
import blog from '../images/lux_images/blogpreview.png';
import LuxItineraries from '../images/lux_images/BookOutlinedIcon-removebg-preview-fotor-20240930144811.png';
import LuxCircuits from '../images/lux_images/fotor-20240930144448.png';
import LuxGolfCourses from '../images/lux_images/GolfCourseOutlinedIcon-removebg-preview-fotor-20240930144550.png';
import LuxExcursions from '../images/lux_images/PetsOutlinedIcon-removebg-preview (1)-fotor-20240930144636.png';
import LuxHotels from '../images/lux_images/luxHotels.png';


export const images = {
    logo: logo,
    homeImage: homeImage,
    contactUs: contactUs,
    aboutUs: aboutUs,
    blog: blog,
    LuxItineraries: LuxItineraries,
    LuxCircuits: LuxCircuits,
    LuxGolfCourses: LuxGolfCourses,
    LuxExcursions: LuxExcursions,
    LuxHotels:LuxHotels,
}