import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Style/Login.css';
import { auth } from '../Firebase/Firebase';
import { Box, Button, TextField, Typography } from '@mui/material';

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const login = (event) => {
    event.preventDefault();
    auth.signInWithEmailAndPassword(email, password)
      .then(() => {
        navigate('/');
      })
      .catch((e) => {
        alert(e.message);
      });
  };

  const register = (event) => {
    event.preventDefault();
    auth.createUserWithEmailAndPassword(email, password)
      .then(() => {
        navigate('/');
      })
      .catch((e) => {
        alert(e.message);
      });
  };

  return (
    <Box className="login">
      <Link to="/">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/1024px-Amazon_logo.svg.png"
          alt=""
          className="login_logo"
        />
      </Link>
      <Box className="login_container">
        <Typography variant="h4">Sign in</Typography>
        <form>
          <Typography>E-mail</Typography>
          <TextField
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            fullWidth
            margin="normal"
          />
          <Typography>Password</Typography>
          <TextField
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            fullWidth
            margin="normal"
          />
          <Button type="submit" onClick={login} className="login_signButton">
            Sign In
          </Button>
        </form>
        <Typography>
          By continuing, you agree to jumia's Conditions of Use and Privacy Notice.
        </Typography>
        <Button className="login_registerButton" onClick={register}>
          Create your jumia Account
        </Button>
      </Box>
    </Box>
  );
}

export default Login;
